import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { CalendarType } from 'core/types/shared/date';
import { SkinCalendarType } from 'features/appBuilder/patterns/types/common/calendar';
import { convertNumberToPersian } from './helperPack';

/**
 * Returns the current date in ISO format.
 *
 * @returns {string} The current date in ISO format.
 */
export const getCurrentIsoDate = (): string => {
  let date = new Date(Date.now());
  return date.toISOString();
};

/**
 * Returns the current date rounded to the nearest hour.
 *
 * @returns {Date} The current date rounded to the nearest hour.
 */
export const getRoundedCurrentDate = (): Date => {
  let date = new Date(Date.now());
  date.setMinutes(0, 0, 0);
  return date;
};

/**
 * Returns a date that is a month later than the current date, rounded to 2 PM.
 *
 * @returns {Date} A date that is a month later than the current date.
 */
export const getRoundedAMonthLaterDate = (): Date => {
  let date = new Date(Date.now() + 30);
  date.setHours(14, 0, 0, 0);
  const future = date.setDate(date.getDate() + 30);
  return new Date(future);
};

/**
 * Returns the locale date.
 *
 * @param {string} date - The iso string to use for getting locale date.
 * @returns {string} The locale date.
 */
export const getLocaleDate = (
  date: string,
  locale: CalendarType | SkinCalendarType = 'jalali',
  pick: 'yyyy/mm/dd' | 'yyyy' = 'yyyy/mm/dd'
): string => {
  const commonOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    ...((pick === 'yyyy/mm/dd' || pick === undefined) && {
      month: '2-digit',
      day: '2-digit',
    }),
  };

  switch (locale) {
    case 'gregorian':
      return convertNumberToPersian(
        new Date(date)
          .toLocaleDateString(undefined, commonOptions)
          .replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2')
      );

    case 'hijri':
    case 'lunar':
      return convertNumberToPersian(
        new Date(date)
          .toLocaleDateString('islamic-umalqura', {
            calendar: 'islamic-umalqura',
            ...commonOptions,
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2') // Rearrange the parts of the date
          .replace('AH', '')
      );

    default:
      return (
        convertNumberToPersian(
          new Date(date).toLocaleDateString('fa-IR', commonOptions)
        ) || ''
      );
  }
};

/**
 * Converts a date to a locale-specific time string.
 *
 * @param {string} date - The date to convert.
 * @param {CalendarType} [type] - The calendar type to use.
 * @returns {string} The time as a locale-specific string.
 */
export const getLocaleTime = (
  date: string,
  type: CalendarType | SkinCalendarType = 'jalali'
): string => {
  const commonOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  switch (type) {
    case 'lunar':
    case 'hijri':
      return new Date(date).toLocaleTimeString('islamic-umalqura', {
        ...commonOptions,
        calendar: 'islamic-umalqura',
      });

    case 'gregorian':
      return new Date().toLocaleDateString('en-US', commonOptions);

    default:
      return new Date(date).toLocaleTimeString('fa-IR', commonOptions);
  }
};

/**
 * Returns the localized date and time string in the specified format.
 *
 * @param {string} date - The date string to be formatted.
 * @param {string} [separator] - The separator between date and time (optional).
 * @returns {string} The localized date and time string.
 */
export const getLocaleDateAndTime = (
  date: string,
  separator?: string,
  locale: CalendarType | SkinCalendarType = 'jalali'
): string =>
  `${getLocaleDate(date, locale)}${
    separator ? ` ${separator}` : ''
  } ${getLocaleTime(date, locale)}`;

/**
 * Returns the calendar pick type name based on the provided pick type.
 *
 * @param {string} pickType - The pick type.
 * @returns {string} The calendar pick type name.
 */
export const getCalendarPickTypeName = (pickType: string): string => {
  switch (pickType) {
    case 'dd':
      return 'روز';
    case 'ddd':
      return 'مخفف نام روز';
    case 'dddd':
      return 'نام روز';
    case 'mm':
      return 'ماه';
    case 'mmm':
      return 'مخفف نام ماه';
    case 'mmmm':
      return 'نام ماه';
    case 'yy':
      return 'سال (دو رقم)';
    case 'yyyy':
      return 'سال';
    default:
      return '؟';
  }
};

/**
 * Generates a calendar label based on the provided pick types.
 *
 * @param {string[]} pickTypes - The array of pick types.
 * @returns {string} The generated calendar label.
 */
export const generateCalendarLabel = (pickTypes: string[]): string => {
  let days = pickTypes
    .filter((pickType) => pickType.includes('d'))
    .sort((a, b) => (a.length > b.length ? -1 : 1));
  const months = pickTypes
    .filter((pickType) => pickType.includes('m'))
    .sort((a, b) => (a.length > b.length ? -1 : 1));
  const years = pickTypes
    .filter((pickType) => pickType.includes('y'))
    .sort((a, b) => (a.length > b.length ? -1 : 1));
  const sorted = [...days, ...months, ...years];
  if (isEqual(sorted, ['dd', 'mm', 'yyyy'])) return 'سال / ماه / روز';
  if (isEqual(sorted, ['mm', 'yyyy'])) return 'سال / ماه';
  if (isEqual(sorted, ['dddd', 'dd', 'mmmm', 'yyyy']))
    return 'نام روز، روز و ماه، سال';
  return 'label';
};

/**
 * Returns the deprecated date picker format based on the provided pick types.
 *
 * @param {string[]} pickTypes - The array of pick types.
 * @returns {string} The deprecated date picker format.
 */
export const deprecatedGetDatePickerFormat = (pickTypes: string[]): string => {
  if (isEqual(pickTypes, ['dd', 'mm', 'yyyy'])) return 'YYYY/MM/DD';
  if (isEqual(pickTypes, ['mm', 'yyyy'])) return 'YYYY/MM';
  if (isEqual(pickTypes, ['dddd', 'dd', 'mmmm', 'yyyy']))
    return 'dddd، DD MMMM YYYY';
  return 'YYYY/MM/DD';
};

/**
 * Checks if the date difference with the current date is more than the given time in minutes.
 *
 * @param {string} dateString - The date string to compare.
 * @param {number} time - The time in minutes.
 * @returns {boolean} True if the date difference is more than the given time, false otherwise.
 */
export const isDateStale = (dateString: string, time: number): boolean => {
  const current = dayjs();
  const date = dayjs(dateString);
  return current.diff(date, 'minutes') > time;
};

