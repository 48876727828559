import * as React from 'react';

// Custom Common Components
import IconBuilder from 'core/components/shared/IconBuilder';

// Custom Types
import type { SvgIconProps } from 'core/components/base/display/SvgIcon';

interface FormatAlignCenterIconProps extends SvgIconProps {}

const FormatAlignCenterIcon: React.FC<FormatAlignCenterIconProps> = (props) => (
  <IconBuilder {...props}>
    <path d='M21.7338 8.85403H4.26615C4.1192 8.85403 4 8.699 4 8.57325V6.03078C4 5.87575 4.11909 5.75 4.26615 5.75H21.7338C21.8808 5.75 22 5.90503 22 6.03078V8.57325C22 8.72828 21.8809 8.85403 21.7338 8.85403Z' />
    <path d='M21.0613 14.0572H5.39115C5.2442 14.0572 5.125 13.9021 5.125 13.7764V11.2339C5.125 11.0789 5.24409 10.9531 5.39115 10.9531H21.0613C21.2082 10.9531 21.3274 11.1082 21.3274 11.2339V13.7764C21.3274 13.9314 21.2083 14.0572 21.0613 14.0572Z' />
    <path d='M17.4651 19.2486H8.77396C8.62701 19.2486 8.50781 19.0935 8.50781 18.9678V16.4253C8.50781 16.2703 8.62691 16.1445 8.77396 16.1445H17.4651C17.612 16.1445 17.7312 16.2996 17.7312 16.4253V18.9678C17.7312 19.1228 17.6121 19.2486 17.4651 19.2486Z' />
  </IconBuilder>
);

export default FormatAlignCenterIcon;
