import * as React from 'react';
import isEqual from 'lodash/isEqual';

// Core Components
import Button from 'core/components/base/inputs/Button';

// Common Components
import RowStack from 'core/components/shared/Stack/RowStack';

// Custom Types
import type { UploadDialogTabType } from 'features/file/files/components/shared/Dialog';
interface FileTabsProps {
  selectedTab: UploadDialogTabType;
  onTabChange: (value: UploadDialogTabType) => void;
  disableScriptSection?: boolean;
  disableUploadSection?: boolean;
  disableFileManagerSection?: boolean;
  disableFilesInSection?: boolean;
}

const FileTabs: React.FC<FileTabsProps> = (props) => {
  // Props
  const {
    selectedTab,
    disableScriptSection,
    disableUploadSection,
    disableFileManagerSection,
    disableFilesInSection,
    onTabChange,
  } = props;

  // Hooks
  const tabs: {
    isActive: boolean;
    value: UploadDialogTabType;
    title: string;
  }[] = React.useMemo(
    () => [
      {
        isActive: !disableUploadSection,
        value: 'upload',
        title: 'بارگذاری فایل',
      },
      {
        isActive: !disableFileManagerSection,
        value: 'file-manager',
        title: 'فایل منیجر',
      },
      {
        isActive: !disableScriptSection,
        value: 'script',
        title: 'اسکریپت',
      },
      {
        isActive: !disableFilesInSection,
        value: 'section-files',
        title: 'فایل‌های آپلود شده در این بخش',
      },
    ],
    [
      disableScriptSection,
      disableUploadSection,
      disableFileManagerSection,
      disableFilesInSection,
    ]
  );

  // Render
  return (
    <RowStack spacing={0} alignItems='center'>
      {tabs
        .filter((tab) => tab.isActive)
        .map((tab) => (
          <Button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            variant='text'
            color={selectedTab === tab.value ? 'info' : 'inherit'}
          >
            {tab.title}
          </Button>
        ))}
    </RowStack>
  );
};

export default React.memo(FileTabs, isEqual);
