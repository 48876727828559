import { useState, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Types
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

// Custom Common Components
import RoundPaper from 'core/components/shared/Paper/RoundPaper';
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import RowStack from 'core/components/shared/Stack/RowStack';
import InputLabel from 'core/components/shared/Typography/InputLabel';
import TextFieldOutlined from 'core/components/shared/TextField/Outlined';

// Custom Core Components
import Button from 'core/components/base/inputs/Button';

// Custom Hooks
import { useAppDispatch, useAppSelector } from 'core/hooks/redux';

// Custom Utilities
import {
  createTable,
  updateTable,
} from 'features/appBuilder/patterns/features/table/store';

// Custom Validation
import {
  TABLE_COL_LIMIT,
  TABLE_ROW_LIMIT,
} from 'features/appBuilder/patterns/validations/table';
import { TablePatternStateDataProps } from '../types';

// Custom Types
interface TablePatternConfigProps {}

const TablePatternConfig: FC<TablePatternConfigProps> = () => {
  // States
  const [changed, setChanged] = useState(false);

  // Hooks
  const dispatch = useAppDispatch();
  const tableData = useAppSelector((state) => state.table.data);

  const { control, handleSubmit, getValues, setValue, reset } =
    useFormContext();

  const handleReset = useCallback(() => {
    const updated: { id: string; data: Partial<TablePatternStateDataProps> } = {
      id: 'drafg-table-pattern',
      data: {
        bgColorOne: getValues('bgColorOne') || 'rgba(0, 0, 0, 0)',
        bgColorTwo: getValues('bgColorOne') || 'rgba(0, 0, 0, 0)',
        bgImage: getValues('bgImage') || null,
        bgImageType: getValues('bgImageType') || 'cover',
        cardBg: '',
        cardCount: 0,
        creator: getValues('creator') || null,
        dividerColor: '',
        initialized: true,
        isActive: true,
        maxWidth: 'lg',
        padding: getValues('padding') || '0px',
        patternTitle: getValues('patternTitle') || '',
        refs: getValues('ref') || {},
        colCount: tableData.colCount || 0,
        rowCount: tableData.rowCount || 0,
        rows: tableData.rows || [],
        selection: tableData.selection,
        sheetIds: tableData.sheetIds || [],
        sortIndex: 0,
        toolbarMode: tableData.toolbarMode,
        type: 'table',
        variant: 0,
      },
    };

    reset({ ...updated.data });
  }, [tableData, reset]);

  useEffect(() => {
    handleReset();
  }, [tableData, handleReset]);

  // Utilties
  const handleFormSubmit: SubmitHandler<any> = (data) => {
    if (
      data.colCount === 0 ||
      data.rowCount === 0 ||
      data.colCount > TABLE_COL_LIMIT ||
      data.rowCount > TABLE_ROW_LIMIT
    )
      return;
    dispatch(createTable({ colCount: data.colCount, rowCount: data.rowCount }));
  };

  return (
    <RoundPaper>
      <RowStack>
        <ColumnStack spacing={0} width='25%'>
          <InputLabel fontSize='14px'>
            {`تعداد سطر (row=1-${TABLE_ROW_LIMIT})`}
          </InputLabel>
          <Controller
            control={control}
            name='rowCount'
            defaultValue={tableData.rowCount}
            rules={{
              onChange: () => {
                setChanged(true);
                setValue('rowCount', Number(getValues('rowCount') || 0));
              },
            }}
            render={({ field, fieldState }) => (
              <TextFieldOutlined
                disabled={tableData.initialized}
                error={
                  fieldState.isTouched && fieldState.error?.message
                    ? true
                    : false
                }
                helperText={fieldState.isTouched && fieldState.error?.message}
                fullWidth
                type='number'
                inputProps={{ min: 1, max: TABLE_ROW_LIMIT }}
                placeholder='تعداد سطر'
                {...field}
              />
            )}
          />
        </ColumnStack>
        <ColumnStack spacing={0} width='25%'>
          <InputLabel fontSize='14px'>
            {`تعداد ستون (column=1-${TABLE_COL_LIMIT})`}
          </InputLabel>
          <Controller
            control={control}
            name='colCount'
            defaultValue={tableData.colCount}
            rules={{
              onChange: () => {
                setChanged(true);
                setValue('colCount', Number(getValues('colCount') || 0));
              },
            }}
            render={({ field, fieldState }) => (
              <TextFieldOutlined
                disabled={tableData.initialized}
                fullWidth
                error={
                  fieldState.isTouched && fieldState.error?.message
                    ? true
                    : false
                }
                helperText={fieldState.isTouched && fieldState.error?.message}
                type='number'
                inputProps={{ min: 1, max: TABLE_COL_LIMIT }}
                placeholder='تعداد ستون'
                {...field}
              />
            )}
          />
        </ColumnStack>
        {!tableData.initialized && (
          <ColumnStack spacing={0} width='12%'>
            <Button
              disabled={!changed}
              onClick={handleSubmit(handleFormSubmit)}
              variant='outlined'
              sx={{ height: '3rem', width: '6prem', marginTop: '1.5rem' }}
            >
              ایجاد
            </Button>
          </ColumnStack>
        )}
      </RowStack>
    </RoundPaper>
  );
};

export default TablePatternConfig;

