export const fileTableHeaderCells = [
  { key: 'table-cell-1', title: 'عنوان' },
  { key: 'table-cell-2', title: 'بارگذاری شده توسط' },
  { key: 'table-cell-3', title: 'اندازه', hasFilter: true, query: 'size' },
  { key: 'table-cell-4', title: 'محل بارگذاری' },
  { key: 'table-cell-5', title: 'متن جایگزین' },
  {
    key: 'table-cell-6',
    title: 'تاریخ و ساعت',
    hasFilter: true,
    query: 'createdAt',
  },
];
