// Utilities
import { initialCreator } from 'core/utilities/creator/initial';
import { genUUID } from 'core/utilities/helper';
import { generateObjectId } from 'core/utilities/helper/id';

// Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import type { ContentBlockItemProps } from 'features/content/contents/types/details/block';

export const genContentBlockItem = (sortIndex = 0): ContentBlockItemProps => ({
  id: `draft-${generateObjectId()}`,
  data: {
    sortIndex,
    text: '',
    gallery: [],
    blockBgColor: '#F5F5F5',
    blockHoverColor: '#F5F5F5',
    direction: 'column',
    padding: '16px',
    galleryWidth: 100,
    textWidth: 100,
    galleryStyles: {
      displayType: 'default',
      width: 180,
      height: 180,
      align: 'center',
      borderRadius: 8,
      objectFit: 'cover',
    },
  },
});

export const initialContent: ContentDetailsProps = {
  id: 'draft',
  data: {
    title: '',
    abstract: '',
    blocks: [genContentBlockItem()],
    categories: [],
    coverImage: null,
    creator: initialCreator,
    isDraft: false,
    publishDate: new Date().toISOString(),
    relatedMediaId: genUUID(),
    showAbstractInContent: true,
    showCoverInContent: true,
    skinId: '',
    skinTitle: '',
    slug: '',
    tags: [],
    viewsCount: 0,
  },
};

export const getInitialContent = (): ContentDetailsProps => {
  const publishDate = new Date().toISOString();

  return {
    id: initialContent.id,
    data: {
      ...initialContent.data,
      publishDate,
    },
  };
};

