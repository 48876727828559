import { v4 as uuidv4 } from 'uuid';
import type { ControllerFieldState } from 'react-hook-form';

// Utilities
import { forLoop } from './array';
import { prefixFileURL } from './link';
import { getAppUserURL } from '../app/info';
import { generateObjectId, isObjectId } from './id';
import { FileProps } from 'features/file/files/types';
import { getBakedLocations } from 'features/file/files/utilities/files';

/**
 * Checks if string starts with / or not. If not / will be added to the start of the string.
 *
 * @param {string} str - Link to prefix with slash.
 * @returns {string} The prefixed link.
 */
export const prefixWithSlash = (str: string): string => {
  return str.startsWith('/') ? str : `/${str}`;
};

/**
 * Trims a string and replace all spaces with _.
 *
 * @param {string} str - The title to convert.
 * @returns {string} The prefixed slug.
 */
export const convertToValidSlug = (str: string): string =>
  str.trim().replaceAll(' ', '_');

/**
 * Converts text to a valid slug.
 *
 * @param {string} str - The title to convert.
 * @returns {string} The converted slug.
 */
export const textToSlug = (str: string): string => {
  if (str.startsWith('http://') || str.startsWith('https://'))
    return convertToValidSlug(str);
  return str ? prefixWithSlash(convertToValidSlug(str)) : '';
};

/**
 * Converts text to a valid url.
 *
 * @param {string} str - The title to convert.
 * @returns {string} The converted url.
 */
export const convertToURL = (str: string): string => {
  if (!str) return '';
  if (str.startsWith('http://') || str.startsWith('https://')) return str;

  const userURL = getAppUserURL();
  return `${userURL}${prefixWithSlash(convertToValidSlug(str))}`;
};

/**
 * Checks href and fix it if it doesn't start with /.
 *
 * @param {string} str - The title to convert.
 * @returns {string} The converted url.
 */
export const prefixHrefWithSlash = (str: string): string =>
  str.startsWith('/') ? str : `/${str}`;

/**
 * Converts number to fa-IR locale string.
 *
 * @param {number} num - The number to convert.
 * @returns {string} The locale string.
 */
export const toLocaleNumString = (num: number): string =>
  num.toLocaleString('fa-IR');

/**
 * Converts number into an string to the persian one.
 *
 * @param {number | number} str - The number to convert.
 * @returns {string} The persian number if the input is not a valid number.
 */
const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const convertNumberToPersian = (str: string | number): string => {
  if (!str) return '';

  // @ts-ignore
  return str.toString().replace(/\d/g, (x) => farsiDigits[x]);
};

export const convertNumberToEnglish = (str: string): string => {
  if (!str) return '';

  let converted = '';

  forLoop(str.split(''), (v) => {
    const indexOfV = farsiDigits.findIndex((value) => value === v);

    if (indexOfV > -1) {
      converted += englishDigits[indexOfV];
    } else {
      converted += v;
    }
  });

  return converted;
};

/**
 * Converts persian number into an string to the latin one.
 *
 * @param {number | number} str - The number to convert.
 * @returns {string | undefined} The latin number .
 */
export const convertPersianDigitsToLatin = (str: string | number): string => {
  const s = typeof str === 'number' ? str.toString() : str;

  // @ts-ignore
  return s.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d).toString());
};

/**
 * Generates a random id.
 *
 * @returns {string} The random id.
 */
export const randNum = (): string => uuidv4();

/**
 * Generates a random ObjectId.
 *
 * Returns an array with the given length with objectId items in it.
 * @returns {string[]} The array with the given length.
 */
export const genObjectIdArray = (size: number): string[] => {
  let ids: string[] = [];
  for (let i = 0; i <= size; i++) {
    ids[i] = generateObjectId();
  }

  return ids;
};

/**
 * Returns number equivalent in persian text.
 *
 * @param {number} number - the number to convert.
 * @param {fa | ar} number - the format of the returned text.
 * @returns {string | undefined} Returns the converted number in persian language or undefined if the input is not a valid number .
 */
export const getNumberTitle = (
  number: number,
  format: 'fa' | 'fa-ordinal' = 'fa-ordinal'
): string | undefined => {
  switch (number) {
    case 0:
      return 'صفر';
    case 1:
      return format === 'fa-ordinal' ? 'اول' : 'یک';
    case 2:
      return format === 'fa-ordinal' ? 'دوم' : 'دو';
    case 3:
      return format === 'fa-ordinal' ? 'سوم' : 'سه';
    case 4:
      return format === 'fa-ordinal' ? 'چهارم' : 'چهار';
    case 5:
      return format === 'fa-ordinal' ? 'پنجم' : 'پنج';
    case 6:
      return format === 'fa-ordinal' ? 'ششم' : 'شش';
    case 7:
      return format === 'fa-ordinal' ? 'هفتم' : 'هفت';
    case 8:
      return format === 'fa-ordinal' ? 'هشتم' : 'هشت';
    case 9:
      return format === 'fa-ordinal' ? 'نهم' : 'نه';
    case 10:
      return format === 'fa-ordinal' ? 'دهم' : 'ده';
    case 11:
      return format === 'fa-ordinal' ? 'یازدهم' : 'یازده';
    case 12:
      return format === 'fa-ordinal' ? 'دوازدهم' : 'دوازده';
    case 13:
      return format === 'fa-ordinal' ? 'سیزدهم' : 'سیزده';
    case 14:
      return format === 'fa-ordinal' ? 'چهاردهم' : 'چهارده';
    case 15:
      return format === 'fa-ordinal' ? 'پانزدهم' : 'پانزده';
    case 16:
      return format === 'fa-ordinal' ? 'شانزدهم' : 'شانزده';
    case 17:
      return format === 'fa-ordinal' ? 'هفدهم' : 'هفده';
    case 18:
      return format === 'fa-ordinal' ? 'هجدهم' : 'هجده';
    case 19:
      return format === 'fa-ordinal' ? 'نوزدهم' : 'نوزده';
    case 20:
      return format === 'fa-ordinal' ? 'بیستم' : 'بیست';
    case 21:
      return format === 'fa-ordinal' ? 'بیست و یکم' : 'بیست و یک';
    case 22:
      return format === 'fa-ordinal' ? 'بیست و دوم' : 'بیست و دو';
    case 23:
      return format === 'fa-ordinal' ? 'بیست و سوم' : 'بیست و سه';
    case 24:
      return format === 'fa-ordinal' ? 'بیست و چهارم' : 'بیست و چهار';
    case 25:
      return format === 'fa-ordinal' ? 'بیست و پنجم 😐' : 'بیست و پنج';
    default:
      return convertNumberToPersian(number.toString());
  }
};

/**
 * Deep clones an object or array using JSON.stringify and JSON.parse.
 *
 * NOTE: DONT use for File,Blob,Functions or objects containg them. It won't clone it.
 * @template T
 * @param {T} state - The object or array to be deep cloned.
 * @returns {T} The deep cloned object or array.
 */
export const deepClone = <T>(state: T): T => JSON.parse(JSON.stringify(state));

/**
 * Scrolls the window to the bottom of the page smoothly.
 * @returns {void}
 */
export const scrollToBottom = (): void =>
  window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });

/**
 * Helper utility function to stringify JSON with support for undefined values.
 * @param data - The data to be stringified.
 * @returns The stringified JSON.
 */
export const stringifyWithUndefined = (data: any): string => {
  return JSON.stringify(data, (_, value) => {
    if (typeof value === 'undefined') return '__undefined__';

    return value;
  });
};

/**
 * Checks if two values are the same by comparing their stringified versions.
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 * @returns {boolean} Returns true if the values are the same, false otherwise.
 */
export const isSame = (a: any, b: any): boolean =>
  stringifyWithUndefined(a) === stringifyWithUndefined(b);

/**
 * Generates a query string from an object.
 * @param {Record<string, any>} obj - The object containing key-value pairs for the query string.
 * @returns {string} The generated query string.
 */
export const generateQueryString = (obj: Record<string, any>): string => {
  const params: string[] = [];

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      const arrayValues = value.map((item) => `"${item}"`);
      params.push(`${encodeURIComponent(key)}=[${arrayValues}]`);
    } else {
      if (value)
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });

  return params.join('&');
};

/**
 * Converts searchParams to a URL query string.
 *
 * @param {URLSearchParams} searchParams - The searchParams object to convert.
 * @param {boolean} [excludePagination] - Optional. If true, excludes pagination parameters ('page' and 'limit') from the query string.
 * @returns {string} The URL query string generated from the searchParams.
 */
export const searchParamsToQuery = (
  searchParams: URLSearchParams,
  excludePagination: boolean,
  acceptIdKeys?: boolean
): string => {
  const query = new URLSearchParams();
  searchParams.forEach((value, key) => {
    const isKeyAnObjectId = isObjectId(key);

    if (
      (!acceptIdKeys &&
        ![
          'search',
          'startDate',
          'endDate',
          'page',
          'limit',
          'location',
          'type',
          'minSize',
          'maxSize',
          'sort',
          'uploadBy',
        ].includes(key)) ||
      (acceptIdKeys &&
        !isKeyAnObjectId &&
        ![
          'search',
          'startDate',
          'endDate',
          'page',
          'sort',
          'type',
          'limit',
          'location',
          'minSize',
          'maxSize',
          'uploadBy',
        ].includes(key))
    )
      return; // Should move to a separete config file for valid keys;

    if (excludePagination && ['page', 'limit'].includes(key)) return;
    query.set(key, value);
  });

  return query.toString();
};

/**
 * Checks if a URL query parameter is a valid JSON string.
 *
 * @param {string} queryParam - The URL query parameter to check.
 * @returns {boolean} True if the query parameter is a valid JSON string, false otherwise.
 */
export const isValidJSON = (queryParam: string): boolean => {
  try {
    JSON.parse(queryParam);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Removes duplicates from an array of strings.
 *
 * @param {string[]} items - The array of strings to remove duplicates from.
 * @returns {string[]} The array with duplicates removed.
 */
export const removeDuplicates = (items: string[]): string[] => {
  const uniqueStrings = new Set<string>();

  items.forEach((str) => uniqueStrings.add(str));

  return Array.from(uniqueStrings);
};

/**
 * This function will check data and detect file type and then return the source for that.
 *
 * Supports File, FileProps, ObjectURL, CustomObjectURL
 * @param {file | FileProps | string } file to check.
 * @returns Returns a string to use as the source for the file.
 **/
export const getFileSource = (file: File | FileProps | string): string => {
  if (!file) return '';
  if (isFile(file)) return URL.createObjectURL(file as File);
  if (isFileProps(file)) return prefixFileURL((file as FileProps).data.url);
  if (isFilePropsLink(file)) return prefixFileURL(file as string);
  if (isOriginalFileObjectURL(file)) return file as string;
  if (isCustomFileObjectURL(file)) {
    const [url] = (file as string).split('&--&');
    return url;
  }

  return '';
};

/**
 * Checks if the given string is a FileProps link string.
 * @param {any} file - The object to check.
 * @returns {boolean} - True if the given string is a FileProps link string, false otherwise.
 */
export const isFilePropsLink = (file: any): boolean => {
  if (typeof file === 'string' && file.length > 0 && file.includes('/storage'))
    return true;
  return false;
};

/**
 * Converts a custom object URL to a file. only works on urls seperated by '&--&'
 *
 * NOTICE: You must use "fileToObjectUrl" function to convert and then parse it with this function.Take a look at below example.
 * @param {string} objectUrl - The object URL to convert.
 * @returns {Promise<File>} A promise that resolves to the converted file.
 * @example
 * const objectUrl = fileToObjectUrl(file as File);
 * objectUrlToFile(customUrl);
 * // Returns a Promise that resolves to the converted file.
 *
 */
export const objectUrlToFile = async (objectUrl: string): Promise<File> => {
  try {
    const [url, name, type] = objectUrl.split('&--&');
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name, { type });
    return file;
  } catch (error) {
    throw error;
  }
};

/**
 * Converts a File to an object URL with additional information.
 *
 * You can use "objectUrlToFile" function to parse this url to a File.
 * @param {File} file - The File to convert.
 * @returns {string} The object URL with appended name and type.
 */
export const fileToObjectUrl = (file: File): string => {
  const objectURL = URL.createObjectURL(file);
  // We need to store type and name so we can parse it back to file.
  return `${objectURL}&--&${file.name}&--&${file.type}`; // &--& is the Seperator between URL and name and Type.
};

/**
 * Checks if the given URL is a original File object URL.
 * @param {any} url - The URL to check.
 * @returns {boolean} True if the URL is a File object URL, false otherwise.
 */
export const isOriginalFileObjectURL = (url: any): boolean =>
  typeof url === 'string' && url.startsWith('blob:') && !url.includes('&--&');

/**
 * Checks if the given URL is a custom File object URL created by 'fileToObjectUrl' function.
 * @param {any} url - The URL to check.
 * @returns {boolean} True if the URL is a File object URL, false otherwise.
 */
export const isCustomFileObjectURL = (url: any): boolean =>
  typeof url === 'string' && url.startsWith('blob:') && url.includes('&--&');

/**
 * Checks if the given object is a File.
 * @param {any} file - The object to check.
 * @returns {boolean} - True if the object is a File, false otherwise.
 */
export const isFile = (file: any): boolean => file instanceof File;

/**
 * Checks if the given object has 'id' and 'data' properties.
 * @param {any} file - The object to check.
 * @returns {boolean} - True if the object has 'id' and 'data' properties, false otherwise.
 */
export const isFileProps = (file: any): boolean => {
  if (typeof file !== 'object') return false;
  if ('id' in file && 'data' in file) return true;
  else return false;
};

/**
 * Returns an object containing the error status and helper text for a given field state.
 *
 * @param {ControllerFieldState} fieldState The field state to get the error and helper text for.
 * @returns {{error: boolean, helperText: string}} An object containing the error status and helper text.
 */
export const getErrorAndHelperText = (fieldState: ControllerFieldState) => ({
  error: fieldState.error ? true : false,
  helperText: fieldState.error?.message || '',
});

/**
 * Constructs a URL with an optional query string.
 *
 * @param {string} url - The base URL to which the query string will be appended.
 * @param {Record<string, any>} [query] - An optional object representing the query parameters.
 * @returns {string} The constructed URL with the query string, if any.
 */
export const getUrlWithQueryString = (
  url: string,
  query?: Record<string, any>
): string => {
  const queries = query || {};
  const searchParams = new URLSearchParams();

  Object.entries(queries).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const values =
        key === 'location'
          ? getBakedLocations(value)
          : value.map((item) => `"${item}"`);
      searchParams.set(key, `[${values}]`);
    } else searchParams.set(key, value);
  });
  const queryString = searchParams.toString();

  return queryString
    ? `${url}${url.includes('?') ? `&${queryString}` : `?${queryString}`}`
    : url;
};

/**
 * Converts a URLSearchParams object into a plain JavaScript object.
 *
 * @param {URLSearchParams} searchParams - The URLSearchParams object containing query parameters.
 * @returns {Record<string, any>} A plain object where the keys are the query parameter names and the values are the corresponding values.
 */
export const getSearchParamsObject = (searchParams: URLSearchParams) => {
  const query: Record<string, any> = {};
  searchParams.forEach((value, key) => {
    if (query[key])
      query[key] = [
        ...(Array.isArray(query[key]) ? query[key] : [query[key]]),
        value,
      ];
    else query[key] = ['type', 'location'].includes(key) ? [value] : value;
  });
  return query;
};

/**
 * Checks if the given array is plural (i.e., has more than one element).
 *
 * @param arr - The array to check.
 * @returns True if the array has more than one element, otherwise false.
 */
export function isPlural<T>(arr: T[]): boolean {
  return arr.length > 1;
}

export function truncate(text: string, length: number): string {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
}
