import type { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

// Core Components
import Box from 'core/components/base/layout/Box';
import Editor from 'core/components/shared/Editor';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import Skeleton from 'core/components/base/feedback/Skeleton';

// Feature Components
import PatternFontFamilyDropdown from 'features/appBuilder/patterns/core/components/shared/dropdown/FontFamily';
import PatternItemContainer, {
  type PatternItemContainerProps,
} from 'features/appBuilder/patterns/core/components/shared/items/Container';

// Core Utilities
import { useSelectLoading } from 'core/store/slices/core/shared/loading';

// Module Types
export interface PatternEditorDescriptionItemProps
  extends Omit<PatternItemContainerProps, 'children'> {
  reference: string;
  editMode?: boolean;
}

const PatternEditorDescriptionItem: FC<PatternEditorDescriptionItemProps> = (
  props
) => {
  // Props
  const { reference, editMode, ...otherProps } = props;

  // Hooks
  const { control, setValue } = useFormContext();
  const loading = useSelectLoading();

  const text = useWatch({
    control,
    name: `${reference}.text`,
  });

  return (
    <PatternItemContainer reference={reference} {...otherProps}>
      <InputWrapper label={'متن'}>
        {editMode ? (
          text !== undefined ? (
            <Controller
              control={control}
              name={`${reference}.text`}
              render={({ field }) => {
                return (
                  <Box
                    sx={{
                      bgcolor: 'background.paper',
                      p: 1,
                      borderRadius: 2,
                      height: '200px',
                    }}
                  >
                    <Editor
                      readonly={text !== undefined || loading}
                      defaultValue={field.value}
                      onBlur={(html) => setValue(field.name, html)}
                    />
                  </Box>
                );
              }}
            />
          ) : (
            <Skeleton variant='rectangular' />
          )
        ) : (
          <Controller
            control={control}
            name={`${reference}.text`}
            render={({ field }) => {
              return (
                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    p: 1,
                    borderRadius: 2,
                    height: '200px',
                  }}
                >
                  <Editor
                    readonly={text !== undefined || loading}
                    defaultValue={field.value}
                    onBlur={(html) => setValue(field.name, html)}
                  />
                </Box>
              );
            }}
          />
        )}
      </InputWrapper>
      <Controller
        control={control}
        name={`${reference}.fontFamily`}
        render={({ field }) => (
          <PatternFontFamilyDropdown
            disabled={loading}
            defaultValue={field.value}
            reference={reference}
            keyName={'fontFamily'}
          />
        )}
      />
    </PatternItemContainer>
  );
};

export default PatternEditorDescriptionItem;
