import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

// Core Components
import Box from 'core/components/base/layout/Box';
import GhostTextField from 'core/components/shared/ResizableTextField';
import InputWrapper from 'core/components/shared/Wrapper/Input';
import Button from 'core/components/base/inputs/Button';

// Core Utilities
import { getErrorAndHelperText } from 'core/utilities/helper/helperPack';
import useYupValidationResolver from 'core/hooks/common/useYupResolver';
import useEffectOnce from 'core/hooks/useEffectOne';
import RowStack from 'core/components/shared/Stack/RowStack';

// Module Types
interface FileScript {
  scriptTitle: string;
  script: string;
  index: number;
}

export interface FileScriptItemProps {
  mode?: 'add' | 'edit';
  scriptTitle?: string;
  script?: string;
  index?: number;
  disabledInsert?: boolean;
  onSubmit: (props: FileScript) => void;
}

const aparatRegex =
  /^<div id="[^"]*">\s*<script type="text\/JavaScript" src="https:\/\/www\.aparat\.com\/embed\/[^"]*"><\/script>\s*<\/div>$/;
const ValidationSchema = Yup.object({
  script: Yup.string()
    .required('اسکریپ نمیتواند خالی باشد.')
    .matches(aparatRegex, 'اسکریپت وارد شده معتبر نیست.'),
});

const FileScriptItem: FC<FileScriptItemProps> = (props) => {
  // Props
  const {
    mode = 'add',
    disabledInsert,
    script,
    scriptTitle,
    index = -1,
    onSubmit,
  } = props;

  // Hooks
  const resolver = useYupValidationResolver(ValidationSchema);
  const { control, reset } = useForm({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      scriptTitle,
      script,
    },
  });

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        script,
        scriptTitle,
      });
    }
  }, [script, scriptTitle, index]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          bgcolor: 'background.default',
          width: '100%',
          borderRadius: '0.5rem',
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Controller
            control={control}
            name='scriptTitle'
            render={({ field }) => (
              <GhostTextField
                variant='standard'
                InputProps={{ sx: { height: '2rem' } }}
                placeholder='عنوان اسکریپت*'
                {...field}
              />
            )}
          />
        </Box>
        <InputWrapper label='کد اسکریپت ویدیو' required>
          <Controller
            control={control}
            name='script'
            render={({ field, fieldState }) => (
              <GhostTextField
                multiline
                rows={3}
                dir={field.value ? 'auto' : 'rtl'}
                variant='standard'
                placeholder='اسکریپت آپارات'
                sx={{ bgcolor: 'background.paper', borderRadius: '0.5rem' }}
                {...getErrorAndHelperText(fieldState)}
                {...field}
              />
            )}
          />
        </InputWrapper>
      </Box>
      {!disabledInsert && (
        <RowStack sx={{ mx: 'auto', mt: '8px' }}>
          {mode === 'edit' && (
            <Button
              color='error'
              variant='outlined'
              sx={{ width: '10rem', borderRadius: '8px' }}
            >
              لغو ویرایش
            </Button>
          )}
          <Controller
            control={control}
            name='script'
            render={({ field: f1, fieldState: f1State }) => (
              <Controller
                control={control}
                name='scriptTitle'
                render={({ field: f2 }) => (
                  <Button
                    disabled={
                      !f1.value ||
                      (f1State.error?.message ? true : false) ||
                      !f2.value
                    }
                    color='info'
                    variant='contained'
                    sx={{
                      borderRadius: '0.5rem',
                      width: '10rem',
                    }}
                    onClick={() =>
                      onSubmit({
                        index,
                        script: f1.value,
                        scriptTitle: f2.value,
                      })
                    }
                  >
                    {mode === 'add' ? 'افزودن' : 'ذخیره'}
                  </Button>
                )}
              />
            )}
          />
        </RowStack>
      )}
    </Box>
  );
};

export default FileScriptItem;
