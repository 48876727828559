import { useState, type FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

// Feature Components
import PatternSectionContainer from 'features/appBuilder/patterns/core/components/shared/SectionContainer';
import PatternMediaItem from 'features/appBuilder/patterns/core/components/shared/items/Media';
import PatternTitleItem from 'features/appBuilder/patterns/core/components/shared/items/Title';
import PatternEditorDescriptionItem from 'features/appBuilder/patterns/core/components/shared/items/EditorDescription';

// Feature Types
import type { StablePatternDataProps } from 'features/appBuilder/patterns/features/stable/types';

// Module Types
export interface StablePatternContentItemProps {
  editMode: boolean;
}
type CollapsedKey =
  | 'section'
  | 'image'
  | 'imageConfig'
  | 'titleOne'
  | 'description';

const StablePatternContentSection: FC<StablePatternContentItemProps> = (
  props
) => {
  // Hooks
  const [collapsed, setCollapsed] = useState<{
    [key in CollapsedKey]: boolean;
  }>({
    section: false,
    image: false,
    imageConfig: false,
    titleOne: false,
    description: false,
  });

  const { control, getValues } = useFormContext<StablePatternDataProps>();
  const variant = useWatch({
    control,
    name: 'variant',
    defaultValue: getValues('variant') || 0,
  });

  // Utilities
  const handleCollapseChanged = (key: CollapsedKey) => (state: boolean) => {
    const newState = { ...collapsed };

    if (key in newState) {
      newState[key] = state;
    }

    setCollapsed(newState);
  };

  return (
    <PatternSectionContainer
      title='محتوای استیبل'
      collapsed={collapsed.section}
      onCollapsedChange={handleCollapseChanged('section')}
      reference='content'
    >
      <PatternMediaItem
        reference='content.image'
        objectFitKeyName='imageType'
        title='رسانه'
        noWidth={variant === 0}
        collapsed={collapsed.image}
        onCollapsedChange={handleCollapseChanged('image')}
        include={['padding', 'borderRadius']}
      />
      <PatternTitleItem
        reference='content.titleOne'
        title='عنوان'
        fontFamily
        collapsed={collapsed.titleOne}
        onCollapsedChange={handleCollapseChanged('titleOne')}
        include={[
          'padding',
          'fontSize',
          'textDirection',
          'textAlign',
          'fontColor',
          'highlightColor',
          'fontHoverColor',
          'highlightHoverColor',
        ]}
      />
      <PatternEditorDescriptionItem
        reference='content.description'
        title='توضیحات'
        fullwidth
        editMode={props.editMode}
        collapsed={collapsed.description}
        onCollapsedChange={handleCollapseChanged('description')}
        include={['padding', 'fontHoverColor', 'highlightHoverColor']}
      />
    </PatternSectionContainer>
  );
};

export default StablePatternContentSection;
