// Core Utilities
import { forLoop } from 'core/utilities/helper';

// Feature Utilities
import { bakePatternCommonProps } from 'features/appBuilder/patterns/core/utilities/api/baker';

// Feature Types
import type {
  TablePatternDataProps,
  TablePatternRefs,
} from 'features/appBuilder/patterns/features/table/types';

/**
 * Transforms the provided table pattern data by ensuring the `creator` field is included as a string.
 *
 * @param {TablePatternDataProps} data - The table pattern data to transform.
 * @returns {Omit<TablePatternDataProps, 'creator'> & { creator: string }} The transformed table pattern data with the `creator` field as a string.
 */
export const bakeTablePatternData = (
  data: TablePatternDataProps
): Omit<TablePatternDataProps, 'creator'> & { creator: string } => {
  const { refs, rows, sheetIds, rowCount, colCount, ...otherProps } = data;

  const commonProps = bakePatternCommonProps(otherProps);

  const bakedSheetIds: string[] = [];
  const bakedRefs: TablePatternRefs = {};

  forLoop(rows, (row) => {
    const rowArray = Object.values(row);

    forLoop(rowArray, (r) => {
      if (r.type === 'ref') {
        if (!bakedSheetIds.includes(r.sheetId)) {
          bakedSheetIds.push(r.sheetId);
        }

        if (!bakedRefs[r.sheetId]) {
          bakedRefs[r.sheetId] = {
            [r.columnKey]: '',
          };
        } else {
          bakedRefs[r.sheetId][r.columnKey] = '';
        }
      }
    });
  });

  return {
    ...commonProps,
    refs: bakedRefs,
    sheetIds: bakedSheetIds,
    rows,
    rowCount,
    colCount,
  };
};
