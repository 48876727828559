import type { FC } from 'react';

// Feature Components
import StablePatternContentSection from 'features/appBuilder/patterns/features/stable/components/Content';

// Custom Types
export interface StablePatternItemsProps {
  editMode?: boolean;
}

const StablePatternItems: FC<StablePatternItemsProps> = (props) => {
  return (
    <>
      <StablePatternContentSection editMode={props.editMode || false} />
    </>
  );
};

export default StablePatternItems;
