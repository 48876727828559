import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { Controller, useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';

// Common Components
import ColumnStack from 'core/components/shared/Stack/ColumnStack';
import Box from 'core/components/base/layout/Box';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import BodyOne from 'core/components/shared/Typography/BodyOne';
import EditIconButton from 'core/components/shared/IconButton/EditIconButton';

// Feature Components
import FileScriptItem from 'features/file/files/components/shared/Dialog/Sections/ScriptItem';

// Custom Utilities
import { customRoundedScrollbarStyles } from 'core/utilities/styles/customStyles';

// Feature Types
import type { FileScriptProps } from 'features/file/files/components/shared/Dialog';

// Module Types
interface FileScriptSectionProps {
  disabledInsert?: boolean;
  onInsertScript: () => void;
}

const FileScriptSection: React.FC<FileScriptSectionProps> = (props) => {
  // Props
  const { disabledInsert, onInsertScript } = props;

  // Hooks
  const [itemToEdit, setItemToEdit] = React.useState<FileScriptProps | null>(
    null
  );
  const { control, getValues, setValue } = useFormContext<{
    scripts: FileScriptProps[];
  }>();

  // Utilities
  const handleAdd = (script: FileScriptProps) => {
    const newValues = cloneDeep(getValues('scripts'));

    newValues.push(script);

    setValue('scripts', newValues);
    onInsertScript();
  };

  const handleEdit = (script: FileScriptProps) => {
    const newValues = cloneDeep(getValues('scripts'));

    const indexToEdit = newValues.findIndex(
      (item) =>
        item.index === itemToEdit?.index &&
        item.script === itemToEdit?.script &&
        item.scriptTitle === itemToEdit?.scriptTitle
    );

    if (indexToEdit > -1) {
      newValues[indexToEdit].index = script.index;
      newValues[indexToEdit].script = script.script;
      newValues[indexToEdit].scriptTitle = script.scriptTitle;

      setValue('scripts', newValues);
      onInsertScript();
    }
  };

  // Render
  return (
    <ColumnStack
      spacing='1rem'
      sx={{ borderRadius: '1rem', paddingBottom: '1rem' }}
    >
      <ColumnStack
        spacing={1}
        sx={{
          width: '100%',
          height: '40vh',
          overflow: 'auto',
          flexDirection: 'column',
          paddingX: '1rem',
          ...customRoundedScrollbarStyles,
        }}
      >
        <Controller
          control={control}
          name='scripts'
          render={({ field }) => (
            <>
              {field.value.map((item) => (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'background.default',
                    p: '1rem',
                    borderRadius: '0.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BodyOne flexGrow={1}>{item.scriptTitle}</BodyOne>
                    {itemToEdit === null && (
                      <EditIconButton onClick={() => setItemToEdit(item)} />
                    )}
                  </Box>
                  <BodyTwo dir='ltr' sx={{ width: '100%', textAlign: 'right' }}>
                    {item.script}
                  </BodyTwo>
                </Box>
              ))}
            </>
          )}
        />
      </ColumnStack>
      <FileScriptItem
        disabledInsert={disabledInsert}
        mode={itemToEdit === null ? 'add' : 'edit'}
        index={itemToEdit?.index || -1}
        script={itemToEdit?.script}
        scriptTitle={itemToEdit?.scriptTitle}
        onSubmit={(script) => {
          if (itemToEdit?.script) {
            handleEdit(script);
          } else {
            handleAdd(script);
          }
        }}
      />
    </ColumnStack>
  );
};

export default React.memo(FileScriptSection, isEqual);

