export const extractPaddingValues = (padding: string) => {
  const values = padding
    .replaceAll('px', '')
    .split(/\s+/)
    .map((value) => value.trim());

  switch (values.length) {
    case 1:
      return {
        top: parseInt(values[0]),
        right: parseInt(values[0]),
        bottom: parseInt(values[0]),
        left: parseInt(values[0]),
      };
    case 2:
      return {
        top: parseInt(values[0]),
        right: parseInt(values[1]),
        bottom: parseInt(values[0]),
        left: parseInt(values[1]),
      };
    case 3:
      return {
        top: parseInt(values[0]),
        right: parseInt(values[1]),
        bottom: parseInt(values[2]),
        left: parseInt(values[1]),
      };
    case 4:
      return {
        top: parseInt(values[0]),
        right: parseInt(values[1]),
        bottom: parseInt(values[2]),
        left: parseInt(values[3]),
      };
    default:
      return {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      };
  }
};
