// Types
import type { UseFormGetValues } from 'react-hook-form';

// Custom Utilities
import { deepClone } from 'core/utilities/helper/helperPack';
import {
  deprecatedfileFeatureLocationOptions,
  deprecatedFileTypesOptions,
} from './initial';

// Cusotm Types
import type { FileFilterProps, FileProps } from 'features/file/files/types';
import type { ParamsProps } from 'core/hooks/api/useDeprecatedDocs';

/**
 * Checks if all options are selected based on the provided state.
 *
 * @param {string[]} option - The array of options to compare against.
 * @param {string[]} state - The array of selected options.
 * @returns {boolean} True if all options are selected, false otherwise.
 */
export const isAllSelected = (option: string[], state: string[]): boolean =>
  option.length > 0 && state.length === option.length;

/**
 * Handles the filtering of files based on the provided form values and executes the filter callback.
 *
 * @param {function} getValues - The function to get the form values.
 * @param {function} onFilter - The filter callback function.
 * @returns {Promise<void>} A promise that resolves once the filtering is complete.
 */
export const handleFilter = async (
  getValues: UseFormGetValues<FileFilterProps>,
  onFilter: (query: ParamsProps) => Promise<void>
): Promise<void> => {
  const queries = deepClone(getValues()) as Partial<FileFilterProps>;

  if (queries?.type && isAllSelected(deprecatedFileTypesOptions, queries.type))
    delete queries['type'];
  if (
    queries?.location &&
    isAllSelected(deprecatedfileFeatureLocationOptions, queries.location)
  )
    delete queries['location'];

  await onFilter(queries);
};

export const getFileTitle = (file: FileProps) => {
  try {
    if (file) {
      return file.data.title.substring(file.data.title.lastIndexOf('_-_') + 3);
    }
    return '';
  } catch (error) {
    return file?.data?.title || '';
  }
};
