import { useState, type FC } from 'react';
import { type Editor } from '@tiptap/react';
import { sanitize } from 'isomorphic-dompurify';

// Core Components
import Box from 'core/components/base/layout/Box';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import InsertLinkIcon from 'core/components/icons/InsertLink';
import TextField from 'core/components/base/inputs/TextField';
import IconButton from 'core/components/base/inputs/IconButton';
import CheckIcon from 'core/components/icons/Check';

// Core Utilities
import useMount from 'core/hooks/useMount';

// Component Types
interface EditorBubbleMenuLinkModeProps {
  editor: Editor | null;
  onClose?: () => void;
}

const EditorBubbleMenuLinkMode: FC<EditorBubbleMenuLinkModeProps> = (props) => {
  // Props
  const { editor, onClose } = props;

  // Hooks
  const [value, setValue] = useState<string>('');

  useMount(() => {
    if (editor) {
      const link = editor.getAttributes('link').href;

      if (link) setValue(link);
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.25rem',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <CloseIconButton color='inherit' onClick={onClose} />
      <InsertLinkIcon />
      <TextField
        fullWidth
        placeholder='https://mysitesample.ir'
        variant='standard'
        value={value}
        onChange={(e) => {
          const value = sanitize(e.target.value.trim() || '');

          setValue(value);
        }}
      />
      <IconButton
        onClick={() => {
          if (value) {
            editor
              ?.chain()
              .focus()
              .extendMarkRange('link')
              .setLink({
                href: value,
                target: value.startsWith('/') ? '_self' : '_blank',
              })
              .run();
          } else {
            editor?.chain().focus().extendMarkRange('link').unsetLink().run();
          }

          onClose && onClose();
        }}
      >
        <CheckIcon />
      </IconButton>
    </Box>
  );
};

export default EditorBubbleMenuLinkMode;
