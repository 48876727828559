import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import { cloneDeepWith } from 'lodash';

// Core Components
import Box from 'core/components/base/layout/Box';
import ConfigIconButton from 'features/form/processes/components/details/shared/ConfigIconButton';
import PlusIcon from 'core/components/icons/Plus';
import DeleteIconButton from 'core/components/shared/IconButton/Delete';
import SortIconButton from 'core/components/shared/inputs/iconButtons/sort';
import CloseIconButton from 'core/components/shared/IconButton/Close';
import DragIcon from 'core/components/icons/Drag';
import SaveIconButton from 'core/components/shared/IconButton/Save';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';

// Core Utilities
import { forLoop, generateObjectId } from 'core/utilities/helper';
import { prefixFileURL } from 'core/utilities/helper/link';
import useBoolean from 'core/hooks/useBoolean';
import useUpdateEffect from 'core/hooks/useUpdateEffect';

// Feature Utilities
import { useUploadDialogContext } from 'features/file/files/context/UploadDialog';
import { transformFileMemeType } from 'features/file/files/utilities/file';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';
import type { FileProps } from 'features/file/files/types';
import type {
  ContentBlockGalleryItemDataProps,
  ContentBlockGalleryItemProps,
} from 'features/content/contents/types/details/block/gallery';

// Component Types
interface ContentDetailsBlockGalleryProps {
  index: number;
  hidden?: boolean;
  onOpenSetting?: () => void;
}

const ContentDetailsBlockGallery: FC<ContentDetailsBlockGalleryProps> = (
  props
) => {
  // Props
  const { index = 0, hidden, onOpenSetting } = props;

  // Hooks
  const [galleryItems, setGalleryItems] = useState<
    ContentBlockGalleryItemProps[]
  >([]);

  const isSorting = useBoolean();
  const { control, setValue, getValues } =
    useFormContext<ContentDetailsProps>();
  const { setSettings } = useUploadDialogContext();

  useUpdateEffect(() => {
    setGalleryItems(getValues(`data.blocks.${index}.data.gallery`));
  }, [isSorting.state]);

  // Utilities
  const handleFilesChange = (files: FileProps[]) => {
    const galleryItems: ContentBlockGalleryItemProps[] = cloneDeepWith(
      getValues(`data.blocks.${index}.data.gallery`)
    );

    forLoop(files, (file) => {
      const hasThisFile =
        galleryItems.findIndex((item) => file.id === item.id) > -1;

      if (!hasThisFile) {
        galleryItems.push({
          id: file.id,
          data: {
            sortIndex: galleryItems.length,
            type: 'file',
            file,
          },
        });
      }
    });

    galleryItems.sort((a, b) => a.data.sortIndex - b.data.sortIndex);
    setValue(`data.blocks.${index}.data.gallery`, galleryItems);
  };

  const handleScriptsChange = (
    scripts: (Omit<ContentBlockGalleryItemDataProps, 'file' | 'sortIndex'> & {
      index: number;
    })[]
  ) => {
    const galleryItems = cloneDeepWith(
      getValues(`data.blocks.${index}.data.gallery`)
    ).filter((item) => item.data.type !== 'script');

    forLoop(scripts, ({ index, ...script }) => {
      galleryItems.push({
        id: generateObjectId(),
        data: {
          sortIndex: index === -1 ? galleryItems.length + 1 : index,
          type: 'script',
          ...script,
        },
      });
    });

    galleryItems.sort((a, b) => a.data.sortIndex - b.data.sortIndex);

    forLoop(galleryItems, (item, index) => (item.data.sortIndex = index));

    setValue(`data.blocks.${index}.data.gallery`, galleryItems);
  };

  const handleOpenDialog = () => {
    setSettings({
      open: true,
      location: 'content',
      accept: ['image', 'audio', 'document', 'video'],
      multiple: true,
      maxSelect: 120,
      selectedFiles: getValues(`data.blocks.${index}.data.gallery`)
        .filter((item) => item.data.type !== 'script')
        .map((galleryItem) => galleryItem.data.file!),
      scripts: getValues(`data.blocks.${index}.data.gallery`)
        .filter((item) => item.data.type === 'script')
        .map((item) => ({
          index: item.data.sortIndex,
          script: item.data.script || '',
          scriptTitle: item.data.scriptTitle || '',
        })),
      disableScriptSection: false,
      onInsertScript: (scripts) => {
        handleScriptsChange(scripts);
      },
      onInsertFile: (files) => {
        if (files.length > 0) {
          handleFilesChange(files);
        }
      },
    });
  };

  const handleDeleteFile = (fileIndex: number) => {
    const galleryItems = getValues(`data.blocks.${index}.data.gallery`) || [];

    galleryItems.splice(fileIndex, 1);

    setValue(`data.blocks.${index}.data.gallery`, galleryItems);
  };

  const handleCancelSort = () => {
    setValue(`data.blocks.${index}.data.gallery`, galleryItems);
    isSorting.setFalse();
  };

  const handleGalleryItemSorts = (sorted: ContentBlockGalleryItemProps[]) => {
    const sortedItems: ContentBlockGalleryItemProps[] = [];

    forLoop(sorted, (sortedItem, index) => {
      sortedItems.push({
        id: sortedItem.id,
        data: {
          file: sortedItem.data.file,
          sortIndex: index,
          script: sortedItem.data.script,
          scriptTitle: sortedItem.data.scriptTitle,
          type: sortedItem.data.type
            ? sortedItem.data.type
            : sortedItem.data.script
            ? 'script'
            : 'file',
        },
      });
    });

    setValue(`data.blocks.${index}.data.gallery`, sortedItems);
  };

  return (
    <Controller
      control={control}
      name={`data.blocks.${index}.data.gallery`}
      render={({ field }) => (
        <Box
          sx={{
            position: 'relative',
            width: '278px',
            minHeight: '200px',
            borderRadius: 2,
            maxHeight: '100%',
            alignSelf: 'stretch',
            border: '2px dashed',
            borderColor: 'divider',
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: 0.5,
          }}
        >
          <ReactSortable
            className='customScroll'
            list={cloneDeepWith(field.value)}
            setList={(sorted) => handleGalleryItemSorts(sorted)}
            disabled={!isSorting.state}
            style={{
              flexGrow: 1,
              padding: '4px',
              height: '100%',
              display: 'flex',
              gap: isSorting.state ? '8px' : '12px',
              alignContent: 'flex-start',
              flexWrap: 'wrap',
              overflowY: field.value.length > 7 ? 'scroll' : 'auto',
              alignItems: field.value.length === 0 ? 'center' : undefined,
              justifyContent: field.value.length === 0 ? 'center' : undefined,
            }}
          >
            {field.value.map((galleryItem, galleryItemIndex) => (
              <Box
                key={galleryItem.id}
                sx={{
                  display: hidden ? 'none' : 'flex',
                  alignItems: 'center',
                  gap: 1,
                  width: isSorting.state ? '100%' : '75px',
                  height: isSorting.state ? '44px' : '75px',
                  borderRadius: '8px',
                  px: isSorting.state ? 0.5 : undefined,
                  ':hover': {
                    bgcolor: 'background.default',
                  },
                }}
              >
                {isSorting.state && (
                  <DragIcon className='handle' sx={{ cursor: 'grab' }} />
                )}
                {isSorting.state && (
                  <BodyTwo
                    dir='ltr'
                    sx={{
                      width: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {galleryItem.data.type === 'script'
                      ? galleryItem.data.scriptTitle?.slice(0, 16)
                      : galleryItem.data.file?.data.title.slice(
                          galleryItem.data.file.data.title.lastIndexOf('_-_') +
                            3
                        )}
                  </BodyTwo>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    width: isSorting.state ? '36px' : '75px',
                    height: isSorting.state ? '36px' : '75px',
                    bgcolor: 'background.default',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    ':hover > img': {
                      opacity: 0.8,
                    },
                    ':hover > .deleteIcon': {
                      opacity: 1,
                    },
                  }}
                >
                  {galleryItem.data.file?.data.mimeType.includes('image') ? (
                    <img
                      src={prefixFileURL(galleryItem.data.file.data.url)}
                      style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <BodyTwo textAlign='center'>
                      {galleryItem.data.type === 'script'
                        ? galleryItem.data.scriptTitle
                          ? galleryItem.data.scriptTitle.slice(0, 12)
                          : 'Script'
                        : transformFileMemeType(
                            galleryItem.data.file?.data.mimeType
                          ).toUpperCase()}
                    </BodyTwo>
                  )}
                  {!isSorting.state && (
                    <DeleteIconButton
                      className='deleteIcon'
                      sx={{
                        opacity: 0,
                        transition: 'all 200ms',
                        bgcolor: 'rgba(255, 255, 255, 0.5)',
                        position: 'absolute',
                        zIndex: 10,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: 8,
                      }}
                      onClick={() => handleDeleteFile(galleryItemIndex)}
                    />
                  )}
                </Box>
              </Box>
            ))}
            <Box
              sx={{
                position:
                  isSorting.state || field.value.length === 0
                    ? 'absolute'
                    : 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                width: field.value.length === 0 ? '100%' : '75px',
                height: field.value.length === 0 ? '100%' : '75px',
                cursor: 'pointer',
                transition: 'all 200ms',
                borderRadius: 2,
                ':hover': {
                  bgcolor: 'rgba(125, 125, 125, 0.05)',
                },
              }}
              onClick={handleOpenDialog}
            >
              <PlusIcon color='info' />
            </Box>
          </ReactSortable>
          <Box
            dir='ltr'
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: 8,
              transition: 'all 200ms',
              ':hover': {
                bgcolor: 'rgba(255, 255, 255, 0.95)',
              },
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              {!isSorting.state ? (
                <>
                  <ConfigIconButton onClick={onOpenSetting} />
                  <SortIconButton onClick={isSorting.setTrue} />
                </>
              ) : (
                <>
                  <CloseIconButton onClick={handleCancelSort} />
                  <SaveIconButton
                    disableRipple={false}
                    onClick={isSorting.setFalse}
                    sx={{ borderRadius: '100%' }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    />
  );
};

export default ContentDetailsBlockGallery;
