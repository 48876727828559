import { type FC } from 'react';

// Core Components
import Box from 'core/components/base/layout/Box';
import Button, { type ButtonProps } from 'core/components/base/inputs/Button';
import FileUploadIcon from 'core/components/icons/FileUpload';
import BodyTwo from 'core/components/shared/Typography/BodyTwo';
import BinIconButton from 'core/components/shared/IconButton/Bin';

//  Core Utilities
import { prefixFileURL } from 'core/utilities/helper/link';

// Custom Types
import type { FileProps } from 'features/file/files/types';
export interface GalleryUploadButtonProps extends ButtonProps {
  label?: string;
  file?: FileProps;
  onOpenUploadDialog?: () => void;
  onRemove?: () => void;
  onPreview?: () => void;
}

const GalleryUploadButton: FC<GalleryUploadButtonProps> = (props) => {
  // Props
  const {
    file,
    label = 'آپلود',
    children,
    color = 'info',
    startIcon = <FileUploadIcon />,
    variant = 'outlined',
    sx,
    disabled,
    onOpenUploadDialog,
    onRemove,
    onPreview,
    ...otherProps
  } = props;

  // Utilities
  const getFileTitle = () => {
    if (file) {
      return file.data.title.substring(
        file.data.title.lastIndexOf('_-_') + 3,
        file.data.title.lastIndexOf('.')
      );
    }
    return '-';
  };

  return file ? (
    <Box
      onClick={onOpenUploadDialog}
      sx={{
        borderRadius: '0.25rem',
        p: '0.25rem 0.5rem',
        border: '1px dashed',
        borderColor: 'text.secondary',
        overflow: 'hidden',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        cursor: disabled ? 'auto' : 'pointer',
        ...sx,
      }}
    >
      {file.data.type === 'video' ? (
        <video
          src={prefixFileURL(file.data.url)}
          style={{ width: 24, height: 24, objectFit: 'cover' }}
          autoPlay={false}
        />
      ) : (
        <img
          src={prefixFileURL(file.data.url)}
          style={{ width: 24, height: 24, objectFit: 'cover' }}
        />
      )}
      <BodyTwo
        dir='ltr'
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '4rem',
          overflow: 'hidden',
        }}
      >
        {getFileTitle()}
      </BodyTwo>
      <BinIconButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onRemove && onRemove();
        }}
        color='error'
        size='small'
      />
    </Box>
  ) : (
    <Button
      color={color}
      startIcon={startIcon}
      variant={variant}
      disabled={disabled}
      fullWidth
      onClick={onOpenUploadDialog}
      sx={{ border: '1px dashed', borderColor: 'text.secondary', ...sx }}
      {...otherProps}
    >
      {label}
    </Button>
  );
};

export default GalleryUploadButton;
