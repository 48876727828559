import * as React from 'react';

// Components
import IconButton from 'core/components/base/inputs/IconButton';
import Tooltip from 'core/components/base/display/Tooltip';

// Icon Components
import EditPenIcon from 'core/components/icons/EditPen';

import type { IconButtonProps } from 'core/components/base/inputs/IconButton';
interface EditIconButtonProps extends IconButtonProps {
  disableTooltip?: boolean;
}

const EditIconButton: React.FC<EditIconButtonProps> = (props) => {
  const { color = 'inherit', disabled, disableTooltip, ...otherProps } = props;

  const Base = (
    <IconButton {...otherProps}>
      <EditPenIcon />
    </IconButton>
  );

  const WithTooltip = <Tooltip title='ویرایش'>{Base}</Tooltip>;

  return disabled && disableTooltip ? Base : WithTooltip;
};

export default EditIconButton;

