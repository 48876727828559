import { useForm, FormProvider } from 'react-hook-form';

// Common Components
import PageHeadConfigs from 'core/components/shared/PageTitle';

// Feature Components
import ContentData from 'features/content/contents/components/details/ContentsData';

// Core Utilities
import { useYupValidationResolver } from 'core/hooks/common/useYupResolver';

// Feature Utilities
import { ContentSchema } from 'features/content/contents/validations';
import { getInitialContent } from 'features/content/contents/utilities/content';

// Feature Types
import type { ContentDetailsProps } from 'features/content/contents/types/details';

// Component Types

interface AddContentProps {}

const AddContent: React.FC<AddContentProps> = () => {
  // Hooks
  const initialContent = getInitialContent();
  const resolver = useYupValidationResolver(ContentSchema);
  const formMethods = useForm<ContentDetailsProps>({
    mode: 'onTouched',
    resolver,
    defaultValues: initialContent,
  });

  // Render
  return (
    <>
      <PageHeadConfigs pageTitle='مطلب جدید' />
      <FormProvider {...formMethods}>
        <ContentData />
      </FormProvider>
    </>
  );
};

export default AddContent;

